<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => f()"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="product-categories"
        title="Product Categories">
        <template v-slot:body>
            <Setup-Block>
                <v-tabs-items v-model="stage" touchless>
                    <v-tab-item>
                        <BT-List-Endless
                            :canSearch="false"
                            :loadingMsg.sync="loadingMsg"
                            maxHeight="60vh"
                            navigation="product-categories"
                            :refreshToggle="refreshList">
                            <template v-slot="{ item }">
                                <v-list-item-avatar>
                                    <v-img :src="productLogoURL(item.id)" class="my-auto">
                                        <template v-slot:placeholder><v-icon class="primary--text" size="40">{{ $BlitzIt.navigation.findIcon('product-categories') }}</v-icon></template>
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.categoryName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.categoryDescription }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn small text @click="addNew(item)">change</v-btn>
                                </v-list-item-action>
                            </template>
                        </BT-List-Endless>

                        <Setup-More :settings="settings">
                            <Setup-Info>
                                Product categories can be helpful for bunching products into logical groups to save having to scroll through all products when ordering.
                            </Setup-Info>
                            
                            <Setup-Example>
                                If you sell dairy products, you can create categories including yogurt, milk, cheese, etc.  And when a customer goes to place an order they can browse through the different categories.
                            </Setup-Example>
                        </Setup-More>

                        <p class="my-4">Would you like to add a category?</p>
                    </v-tab-item>

                    <v-tab-item>
                        <BT-Field-String
                            label="Category Name"
                            v-model="newItem.categoryName"
                            isEditing />

                        <BT-Btn 
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </v-tab-item>

                    <v-tab-item>
                        <BT-Field-Text-Area
                            label="Category Description"
                            v-model="newItem.categoryDescription"
                            isEditing />

                        <BT-Btn 
                            buttonClass="primary mx-1"
                            inline
                            label="Next"
                            @click="save" />
                    </v-tab-item>

                    <v-tab-item>
                        <p>Would you like to upload an image for this category?</p>
                        
                        <BT-Image-Edit
                            canEdit
                            :height="120"
                            :id="newItem.id"
                            justify-center align-center
                            navigation="product-image"
                            :src="productLogoURL(newItem.id) + '?' + cacheData"
                            @uploaded="cacheData = new Date().getTime()"
                            :width="120" />
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Next"
                            @click="stage += 1" />
                    </v-tab-item>

                    <v-tab-item>
                        <p>What products are in this category?</p>
                        
                        <v-expansion-panels v-model="panelV">
                            <BT-Blade-Expansion-Links
                                label="Products"
                                manyNavigation="product-ordering"
                                filterProp="CategoryID"
                                :singleID="newItem.id"
                                :itemProperties="['ID','ProductName','CategoryID']"
                                :resetToggle="resetToggle"
                                singleProp="categoryID"
                                startEditing
                                startOpen
                                manyText="productName"
                                :searchProps="['productName', 'abbreviation']"
                                small />
                        </v-expansion-panels>
                        
                        <BT-Btn
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Done"
                            @click="finish" />
                    </v-tab-item>

                </v-tabs-items>
            </Setup-Block>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Product-Category-Setup',
    components: {
        BTBladeExpansionLinks: () => import('~components/BT-Blade-Expansion-Links.vue'),
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupMore: () => import('~home/setup/Setup-More.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            cacheData: new Date().getTime(),
            loadingMsg: null,
            newItem: {},
            panelV: 0,
            refreshList: false,
            resetToggle: false,
            stage: 0,
            isJustNew: false,
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.newItem = j || { };
            this.stage += 1;
        },
        next(nextFunc) {
            nextFunc();
        },
        nextStage(obj, key, val, stageNum = 1) {
            obj[key] = val;
            this.stage += stageNum;
        },
        
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('product-categories', this.newItem);
                    this.isJustNew = true;
                }
                else {
                    res = await this.$BlitzIt.store.patch('product-categories', this.newItem);
                    this.isJustNew = false;
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.stage += 1;
                this.resetToggle = !this.resetToggle;
                // this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        finish() {
            this.stage = 0;
            this.refreshList = !this.refreshList;
        }
    }
}
</script>